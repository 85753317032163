import React, { ReactElement } from 'react';

import { recordEvent } from '@/configs/metricsConfig/metricsConfig';
import { store } from '@/configs/storeConfig/storeConfig';
import { getMisconfigurationDataForSite } from '@/library/apiServices/scheduleWOServices';
import { uiStrings } from '@/library/constants/uiStrings';
import {
  disableWORecommendationsLoader,
  fetchAllShiftsForSite,
  fetchAllTechniciansForSite,
  fetchRecommendationGenerationStatusForSite,
  fetchWORecommendations,
  fetchWORecommendationsMetaData,
  fetchWorkOrderTypeToScreenIdMappingRequest,
  resetScheduleWOTab,
  updateScheduleWOMessageBanner,
} from '@/library/storeSlices/scheduleWOData/scheduleWODataActions';
import {
  iEndorseWorkOrdersOverwriteReasonsS3Data,
  iEndorseWorkOrdersS3Data,
  iEndorseWorkOrdersS3DataEndorsementDetails,
  iEndorseWorkOrdersS3DataEndorsementDetailsAdditionalTech,
  iFormattedRecommendationsData,
  iSectionizedList,
  iTechnicianConfiguration,
  iTechnicianConfigurationRequestParams,
  iTechnicianData,
} from '@/library/storeSlices/scheduleWOData/scheduleWODataTypes';
import {
  formatRecommendationGenerationData,
  formatSiteMisconfigurationData,
} from '@/library/storeSlices/scheduleWOData/scheduleWODataUtils';
import {
  formatDate,
  getDateUsingTimeZoneOffSet,
  getDaysDifferenceBetweenDates,
  getUTCDate,
  getUTCDateByDateString,
} from '@/library/utils/dateUtils';
import { AppRegions, getCurrentRegion } from '@/library/utils/environmentUtils';
import { roundOffToDecimalPlaces } from '@/library/utils/numberUtils';
import { formUiStringWithParameter } from '@/library/utils/stringUtils';

export const getSectionizedFormattedListOfTechnicians = (
  techniciansList: iTechnicianConfiguration[] = [],
  requiredAssets,
  shift,
  shiftLaborRollupForDate,
  techniciansToBeDisabled,
): iSectionizedList[] => {
  return getCurrentRegion() !== AppRegions.EU
    ? formatSectionizedListForNA(
        techniciansList,
        shift,
        shiftLaborRollupForDate,
        techniciansToBeDisabled,
      )
    : formatSectionizedListForEU(
        techniciansList,
        requiredAssets,
        shift,
        shiftLaborRollupForDate,
        techniciansToBeDisabled,
      );
};

export const formatSectionizedListForEU = (
  techniciansList: iTechnicianConfiguration[] = [],
  requiredAssets,
  shift,
  shiftLaborRollupForDate,
  techniciansToBeDisabled,
): iSectionizedList[] => {
  if (techniciansList.length === 0) return [];
  const qualifiedTechFromSameShift: iTechnicianData[] = [];
  const qualifiedTechFromDifferentShift: iTechnicianData[] = [];
  const qualifiedTech: iTechnicianData[] = [];
  const nonQualifiedTech: iTechnicianData[] = [];
  const zeroQualifiedTech: iTechnicianData[] = [];

  let sectionizedList;

  techniciansList.forEach(tech => {
    const isTechnicianQualified = requiredAssets.every(asset =>
      tech.certifiedAssets.includes(asset),
    );
    const { allocatedHours, maximumAllowedHours } =
      shiftLaborRollupForDate?.[tech.shiftCode]?.[tech.technicianId] ?? {};
    const techData = {
      label: tech.technicianId,
      value: tech.technicianId,
      description:
        tech.shiftCode +
        (maximumAllowedHours
          ? ` | ${uiStrings.shiftLaborRollup} - ${roundOffToDecimalPlaces(
              allocatedHours ?? 0,
              2,
            )}/${roundOffToDecimalPlaces(maximumAllowedHours, 2)}`
          : ''),
      disabled: techniciansToBeDisabled.includes(tech.technicianId),
    };

    if (shift) {
      sectionizedList = [
        {
          label: uiStrings.qualifiedTechniciansSameShift,
          options: qualifiedTechFromSameShift,
        },
        {
          label: uiStrings.qualifiedTechniciansDifferentShift,
          options: qualifiedTechFromDifferentShift,
        },
        {
          label: uiStrings.nonQualifiedTechnicians,
          options: nonQualifiedTech,
        },
        {
          label: uiStrings.zeroQualifiedTechnicians,
          options: zeroQualifiedTech,
        },
      ];

      if (!tech.certifiedAssets.length) {
        zeroQualifiedTech.push(techData);
      } else if (!isTechnicianQualified) {
        nonQualifiedTech.push(techData);
      } else if (tech.shiftCode === shift) {
        qualifiedTechFromSameShift.push(techData);
      } else {
        qualifiedTechFromDifferentShift.push(techData);
      }
    } else {
      sectionizedList = [
        {
          label: uiStrings.qualifiedTechnicians,
          options: qualifiedTech,
        },
        {
          label: uiStrings.nonQualifiedTechnicians,
          options: nonQualifiedTech,
        },
        {
          label: uiStrings.zeroQualifiedTechnicians,
          options: zeroQualifiedTech,
        },
      ];

      if (tech.certifiedAssets.length === 0) {
        zeroQualifiedTech.push(techData);
      } else if (!isTechnicianQualified) {
        nonQualifiedTech.push(techData);
      } else {
        qualifiedTech.push(techData);
      }
    }
  });

  return sectionizedList;
};

export const formatSectionizedListForNA = (
  techniciansList: iTechnicianConfiguration[] = [],
  shift,
  shiftLaborRollupForDate,
  techniciansToBeDisabled,
): iSectionizedList[] => {
  if (techniciansList.length === 0) return [];
  const preferredQualifiedTechFromSameShift: iTechnicianData[] = [];
  const nonPreferredQualifiedTechFromSameShift: iTechnicianData[] = [];
  const preferredQualifiedTechFromDifferentShift: iTechnicianData[] = [];
  const nonPreferredQualifiedTechFromDifferentShift: iTechnicianData[] = [];
  const preferredQualifiedTech: iTechnicianData[] = [];
  const nonPreferredQualifiedTech: iTechnicianData[] = [];
  const nonQualifiedTech: iTechnicianData[] = [];
  const zeroQualifiedTech: iTechnicianData[] = [];

  let sectionizedList;

  techniciansList.forEach(tech => {
    const isPreferredTechnician =
      tech.isPreferredTechnician == null || tech.isPreferredTechnician;
    const isTechnicianQualified = tech.qualificationStatus === 'QUALIFIED';
    const isTechnicianZeroQualified =
      tech.qualificationStatus === 'ZERO_QUALIFIED';
    const { allocatedHours, maximumAllowedHours } =
      shiftLaborRollupForDate?.[tech.shiftCode]?.[tech.technicianId] ?? {};
    const techData = {
      label: tech.technicianId,
      value: tech.technicianId,
      description:
        tech.shiftCode +
        (maximumAllowedHours
          ? ` | ${uiStrings.shiftLaborRollup} - ${roundOffToDecimalPlaces(
              allocatedHours ?? 0,
              2,
            )}/${roundOffToDecimalPlaces(maximumAllowedHours, 2)}`
          : ''),
      disabled: techniciansToBeDisabled.includes(tech.technicianId),
    };

    if (shift) {
      sectionizedList = [
        {
          label: uiStrings.preferredQualifiedTechniciansSameShift,
          options: preferredQualifiedTechFromSameShift,
        },
        {
          label: uiStrings.preferredQualifiedTechniciansDifferentShift,
          options: preferredQualifiedTechFromDifferentShift,
        },
        {
          label: uiStrings.nonPreferredQualifiedTechniciansSameShift,
          options: nonPreferredQualifiedTechFromSameShift,
        },
        {
          label: uiStrings.nonPreferredQualifiedTechniciansDifferentShift,
          options: nonPreferredQualifiedTechFromDifferentShift,
        },
        {
          label: uiStrings.nonQualifiedTechnicians,
          options: nonQualifiedTech,
        },
        {
          label: uiStrings.zeroQualifiedTechnicians,
          options: zeroQualifiedTech,
        },
      ];

      if (isTechnicianZeroQualified) {
        zeroQualifiedTech.push(techData);
      } else if (!isTechnicianQualified) {
        nonQualifiedTech.push(techData);
      } else if (tech.shiftCode === shift) {
        (isPreferredTechnician
          ? preferredQualifiedTechFromSameShift
          : nonPreferredQualifiedTechFromSameShift
        ).push(techData);
      } else {
        (isPreferredTechnician
          ? preferredQualifiedTechFromDifferentShift
          : nonPreferredQualifiedTechFromDifferentShift
        ).push(techData);
      }
    } else {
      sectionizedList = [
        {
          label: uiStrings.preferredQualifiedTechnicians,
          options: preferredQualifiedTech,
        },
        {
          label: uiStrings.nonPreferredQualifiedTechnicians,
          options: nonPreferredQualifiedTech,
        },
        {
          label: uiStrings.nonQualifiedTechnicians,
          options: nonQualifiedTech,
        },
        {
          label: uiStrings.zeroQualifiedTechnicians,
          options: zeroQualifiedTech,
        },
      ];

      if (isTechnicianZeroQualified) {
        zeroQualifiedTech.push(techData);
      } else if (!isTechnicianQualified) {
        nonQualifiedTech.push(techData);
      } else {
        (isPreferredTechnician
          ? preferredQualifiedTech
          : nonPreferredQualifiedTech
        ).push(techData);
      }
    }
  });

  return sectionizedList;
};

interface iEndorsementDataForS3Bucket {
  formattedOverwriteReasonsData: iEndorseWorkOrdersOverwriteReasonsS3Data;
  formattedWOsData: iEndorseWorkOrdersS3Data;
}

const overrideDataConfig = [
  {
    overridenField: 'SHIFT',
    overrideReasonKey: 'overwriteReasons',
    overrideCommentKey: 'overwriteReasonComment',
    oldValueKey: 'recommendedShift',
    newValueKey: 'shift',
  },
  {
    overridenField: 'WO_OWNER',
    overrideReasonKey: 'overwriteReasons',
    overrideCommentKey: 'overwriteReasonComment',
    oldValueKey: 'recommendedWorkOrderOwner',
    newValueKey: 'workOrderOwner',
  },
  {
    overridenField: 'SCHEDULING_BLOCK',
    overrideReasonKey: 'schedulingBlockOverwriteReasons',
    overrideCommentKey: 'schedulingBlockOverwriteReasonComment',
    oldValueKey: 'recommendedSchedulingBlock',
    newValueKey: 'schedulingBlock',
  },
  {
    overridenField: 'SCHEDULE_START_DATE',
    overrideReasonKey: 'overwriteReasons',
    overrideCommentKey: 'overwriteReasonComment',
    oldValueKey: 'recommendedScheduledStartDate',
    newValueKey: 'scheduledStartDate',
  },
];

export const formatEndorsementDataForS3Bucket = (
  endorsementTimestamp,
): iEndorsementDataForS3Bucket => {
  const {
    recommendationsMetadata,
    woRecommendationsMasterDataWithUserEdits,
    selectedWOs,
  } = store.getState().scheduleWODataReducer;
  const { activeSite, userDetails } = store.getState().masterDataReducer;
  const commonEndorsementData = {
    modelRunDate: recommendationsMetadata.modelRunDate ?? '',
    siteId: activeSite?.siteId ?? '',
    endorsedBy: userDetails?.email ?? '',
    endorsementTimestamp,
  };
  const formattedOverwriteReasonsData: iEndorseWorkOrdersOverwriteReasonsS3Data =
    {
      overrideReasons: [],
      ...commonEndorsementData,
    };

  const formattedWOsData: iEndorseWorkOrdersS3Data = {
    endorsedWOs: [],
    ...commonEndorsementData,
  };
  (selectedWOs.length
    ? selectedWOs
    : woRecommendationsMasterDataWithUserEdits
  ).forEach(woData => {
    const additionalTechs: iEndorseWorkOrdersS3DataEndorsementDetailsAdditionalTech[] =
      [];
    woData.secondaryOwnersShifts.forEach((shift, index) => {
      additionalTechs.push({
        shift,
        tech: woData.secondaryOwners[index],
      });
    });
    const data: iEndorseWorkOrdersS3DataEndorsementDetails = {
      workOrderId: `${woData.workOrderID}`,
      workOrderOwner: woData.workOrderOwner ?? '',
      additionalTechs,
      shift: woData.shift ?? '',
      scheduleStartDate: woData.scheduledStartDate,
      schedulingBlock: woData.schedulingBlock ?? '',
      activity: woData.activity,
      trade: woData.trade,
    };
    formattedWOsData.endorsedWOs.push(data);
    overrideDataConfig.forEach(config => {
      if (woData[config.overrideReasonKey]?.length) {
        formattedOverwriteReasonsData.overrideReasons.push({
          workOrderId: `${woData.workOrderID}`,
          overridenField: config.overridenField,
          overrideReason: woData[config.overrideReasonKey],
          overrideComment: woData[config.overrideCommentKey],
          oldValue: woData[config.oldValueKey] ?? '',
          newValue: woData[config.newValueKey] ?? '',
        });
      }
    });
    woData.secondaryOwnersShifts.forEach((shift, index) => {
      formattedOverwriteReasonsData.overrideReasons.push({
        workOrderId: `${woData.workOrderID}`,
        overridenField: 'ADDITIONAL_SHIFT_' + index,
        overrideReason: woData.overwriteReasons,
        overrideComment: woData.overwriteReasonComment,
        oldValue: woData.recommendedSecondaryOwnersShifts[index] ?? '',
        newValue: shift ?? '',
      });
    });
    woData.secondaryOwners.forEach((owner, index) => {
      formattedOverwriteReasonsData.overrideReasons.push({
        workOrderId: `${woData.workOrderID}`,
        overridenField: 'ADDITIONAL_TECH_' + index,
        overrideReason: woData.overwriteReasons,
        overrideComment: woData.overwriteReasonComment,
        oldValue: woData.recommendedSecondaryOwners[index] ?? '',
        newValue: owner ?? '',
      });
    });
  });

  return {
    formattedOverwriteReasonsData,
    formattedWOsData,
  };
};

export const initialiseWorkOrdersData = (dispatch): void => {
  const { activeSite, ottoAppConfig } = store.getState().masterDataReducer;
  dispatch(resetScheduleWOTab());
  dispatch(fetchWorkOrderTypeToScreenIdMappingRequest());
  dispatch(fetchWORecommendationsMetaData()).then(response => {
    getMisconfigurationDataForSite(store).then(response => {
      const { hardCheckCodes, softCheckCodes, genericErrorMessage } =
        formatSiteMisconfigurationData(
          response.misconfiguredEntitiesData,
          ottoAppConfig.ignoredErrorCodes,
        );
      ((hardCheckCodes ?? []).length ||
        (softCheckCodes ?? []).length ||
        !!genericErrorMessage) &&
        dispatch(
          updateScheduleWOMessageBanner({
            type: hardCheckCodes?.length ? 'error' : 'info',
            content: misconfiguredSiteBannerContent({
              hardCheckCodes,
              softCheckCodes,
              genericErrorMessage,
            }),
            dismissible: true,
            id: 'misconfiguredSite',
            onDismiss: () =>
              dispatch(updateScheduleWOMessageBanner('misconfiguredSite')),
          }),
        );
    });
    if (response.payload) {
      const daysDifferenceBetweenModelRunDateAndSiteCurrentDate =
        getDaysDifferenceBetweenDates(
          getUTCDateByDateString(response.payload?.modelRunDate),
          getDateUsingTimeZoneOffSet(getUTCDate(), activeSite?.utcOffset ?? 0),
        );
      if (daysDifferenceBetweenModelRunDateAndSiteCurrentDate <= 4) {
        dispatch(fetchWORecommendations(response.payload?.s3DataFileURL));
        dispatch(fetchAllShiftsForSite());
        dispatch(fetchAllTechniciansForSite());
      } else {
        dispatch(disableWORecommendationsLoader());
      }

      dispatch(
        fetchRecommendationGenerationStatusForSite(
          response.payload?.modelRunDate,
        ),
      ).then(data => {
        const { isNewRunFailed, lastSuccessfulRunDate } =
          formatRecommendationGenerationData(
            data.payload,
            response.payload?.modelRunDate,
          );
        isNewRunFailed &&
          dispatch(
            updateScheduleWOMessageBanner({
              type: 'warning',
              content: `${uiStrings.ottoDailyRunFailed} (${lastSuccessfulRunDate}).`,
              dismissible: true,
              id: 'recommendationGenerationStatusFailed',
              onDismiss: () =>
                dispatch(
                  updateScheduleWOMessageBanner(
                    'recommendationGenerationStatusFailed',
                  ),
                ),
            }),
          );
      });
    }
  });
};

function misconfiguredSiteBannerContent({
  hardCheckCodes,
  softCheckCodes,
  genericErrorMessage,
}): ReactElement {
  const { activeSite, ottoAppConfig } = store.getState().masterDataReducer;
  const { recommendationsMetadata } = store.getState().scheduleWODataReducer;

  return (
    <>
      <p>
        {hardCheckCodes?.length ? (
          <span>
            <strong>{uiStrings.systemFailure}</strong>{' '}
            {recommendationsMetadata.latestModelIngestedTimestamp
              ? formUiStringWithParameter(uiStrings.lastAvailableRunData, {
                  date: formatDate(
                    getUTCDate(
                      new Date(
                        recommendationsMetadata.latestModelIngestedTimestamp ??
                          0,
                      ),
                    ),
                    '{MMM} {DD}, {YYYY} at {hh}:{mm} UTC',
                  ),
                })
              : uiStrings.noLastSuccessfulRun}
          </span>
        ) : (
          formUiStringWithParameter(uiStrings.softFailureDescription, {
            siteId: activeSite?.siteId,
          })
        )}
      </p>
      {genericErrorMessage ? (
        <p>{genericErrorMessage}</p>
      ) : (
        <p>
          {uiStrings.errorCodesFound}
          {[...(hardCheckCodes ?? []), ...(softCheckCodes ?? [])].join(
            ', ',
          )}. {uiStrings.forMoreInformationVisit}{' '}
          <span
            className="otto-wiki-link"
            onClick={() => window.open(ottoAppConfig.ottoWikiLink)}
          >
            {uiStrings.ottoWiki}
          </span>
        </p>
      )}
    </>
  );
}

export const recordSWOEvent = (eventName, eventData?: any): void => {
  const {
    woRecommendationsMasterDataWithoutUserEdits,
    isEditSessionActive,
    workOrdersWithoutUserEditsTableErrors,
    recommendationsMetadata,
    isAnyEditMade,
    startSessionTimestamp,
  } = store.getState().scheduleWODataReducer;
  const swoEventData = {
    totalWOsCount: woRecommendationsMasterDataWithoutUserEdits.length,
    isEditSessionActive,
    recommendedWorkOrdersWithErrorsCount:
      workOrdersWithoutUserEditsTableErrors?.totalWOsWithErrors?.count,
    recommendationsMetadata,
    isAnyEditMade,
    ...(isEditSessionActive
      ? {
          startSessionTimestamp,
          editSessionDuration: Date.now() - startSessionTimestamp,
        }
      : {}),
    ...eventData,
  };
  recordEvent(eventName, swoEventData);
};

interface IOverwriteWOsMetricsData {
  editedWOsCountInEndorsedWOs: number;
  editedSchStartDateCountInEndorsedWOs: number;
  editedShiftCountInEndorsedWOs: number;
  editedTechnicianCountInEndorsedWOs: number;
  overwriteReasonsCount: Record<string, number>;
  otherOverwriteReasons: string[];
}

export const getOverwriteWOsMetricsData = (
  endorsementWorkOrders,
): IOverwriteWOsMetricsData => {
  let editedWOsCountInEndorsedWOs = 0;
  let editedSchStartDateCountInEndorsedWOs = 0;
  let editedShiftCountInEndorsedWOs = 0;
  let editedTechnicianCountInEndorsedWOs = 0;

  const overwriteReasonsCount = {};
  const otherOverwriteReasons: string[] = [];

  endorsementWorkOrders.forEach(woDetails => {
    if (woDetails.isEdited) {
      editedWOsCountInEndorsedWOs = editedWOsCountInEndorsedWOs + 1;
    }
    if (
      woDetails.recommendedScheduledStartDate !== woDetails.scheduledStartDate
    ) {
      editedSchStartDateCountInEndorsedWOs =
        editedSchStartDateCountInEndorsedWOs + 1;
    }

    const isShiftUpdated =
      woDetails.shift !== woDetails.recommendedShift ||
      woDetails.secondaryOwnersShifts.some(
        (secondaryOwnersShift, index) =>
          secondaryOwnersShift !==
          woDetails.recommendedSecondaryOwnersShifts[index],
      );
    if (isShiftUpdated) {
      editedShiftCountInEndorsedWOs = editedShiftCountInEndorsedWOs + 1;
    }

    const isTechnicianUpdated =
      woDetails.workOrderOwner !== woDetails.recommendedWorkOrderOwner ||
      woDetails.secondaryOwners.some(
        (secondaryOwner, index) =>
          secondaryOwner !== woDetails.recommendedSecondaryOwners[index],
      );
    if (isTechnicianUpdated) {
      editedTechnicianCountInEndorsedWOs =
        editedTechnicianCountInEndorsedWOs + 1;
    }

    woDetails.overwriteReasons.forEach(reason => {
      const reasonProperty = reason
        .toLowerCase()
        .replaceAll(' ', '_')
        .replaceAll('.', '_');
      overwriteReasonsCount[reasonProperty] =
        (overwriteReasonsCount[reasonProperty] ?? 0) + 1;
    });
    woDetails.overwriteReasonComment &&
      otherOverwriteReasons.push(woDetails.overwriteReasonComment);
  });

  return {
    editedWOsCountInEndorsedWOs,
    editedSchStartDateCountInEndorsedWOs,
    editedShiftCountInEndorsedWOs,
    editedTechnicianCountInEndorsedWOs,
    overwriteReasonsCount,
    otherOverwriteReasons,
  };
};

export const formatWOsExcelSheetData = (
  workOrdersList,
  shiftLaborRollupData,
): iFormattedRecommendationsData[] => {
  return workOrdersList.map(woData => {
    const shiftLaborRollupList: string[] = [];

    const shiftLaborRollupDataForAllOwners = [
      woData.shift && woData.workOrderOwner
        ? {
            ...shiftLaborRollupData[woData.scheduledStartDate]?.[woData.shift][
              woData.workOrderOwner
            ],
            technician: woData.workOrderOwner,
          }
        : null,
    ];

    [...Array(woData.requiredTechniciansCount - 1).keys()].forEach(index => {
      shiftLaborRollupDataForAllOwners.push(
        woData.secondaryOwnersShifts[index] && woData.secondaryOwners[index]
          ? {
              ...shiftLaborRollupData[woData.scheduledStartDate]?.[
                woData.secondaryOwnersShifts[index]
              ][woData.secondaryOwners[index]],
              technician: woData.secondaryOwners[index],
            }
          : null,
      );
    });

    shiftLaborRollupDataForAllOwners.forEach(data => {
      shiftLaborRollupList.push(
        data?.maximumAllowedHours
          ? `${data.technician}: ${roundOffToDecimalPlaces(
              data?.allocatedHours ?? 0,
              2,
            )}/${roundOffToDecimalPlaces(data?.maximumAllowedHours, 2)} hrs `
          : ' - ',
      );
    });

    return {
      workOrderID: woData.workOrderID,
      scheduledStartDate: woData.scheduledStartDate,
      recommendedScheduledStartDate: woData.recommendedScheduledStartDate,
      shift: woData.shift,
      dueDate: woData.dueDate,
      recommendedShift: woData.recommendedShift,
      secondaryOwnersShifts: woData.secondaryOwnersShifts,
      recommendedSecondaryOwnersShifts: woData.recommendedSecondaryOwnersShifts,
      workOrderOwner: woData.workOrderOwner,
      recommendedWorkOrderOwner: woData.recommendedWorkOrderOwner,
      secondaryOwners: woData.secondaryOwners,
      recommendedSecondaryOwners: woData.recommendedSecondaryOwners,
      shiftLaborRollup: shiftLaborRollupList,
      schedulingBlock: woData.schedulingBlock,
      recommendedSchedulingBlock: woData.recommendedSchedulingBlock,
      ottoStatus: woData.ottoStatus,
      estimatedHours: woData.estimatedHours,
      requiredTechniciansCount: woData.requiredTechniciansCount,
      workOrderDesc: woData.workOrderDesc,
      equipmentAlias: woData.equipmentAlias,
      equipmentDescription: woData.equipmentDescription,
      criticality: woData.criticality,
      precedence: woData.precedence,
      woType: woData.woType,
      pmIntrusiveType: woData.pmIntrusiveType,
      woStatus: woData.woStatus,
      unassignedReason: woData.unassignedReason,
      assets: woData.assets,
      ...(getCurrentRegion() !== AppRegions.EU
        ? { requiredPreferences: woData.requiredPreferences }
        : {}),
      isEdited: woData.isEdited,
      activity: woData.activity,
      trade: woData.trade,
    };
  });
};

export const constructTechConfigurationQueryParams = (
  woData: iFormattedRecommendationsData,
): iTechnicianConfigurationRequestParams => ({
  techAvailabilityDate: woData.scheduledStartDate,
  assets: woData.assets,
  isPMIntrusive: woData.pmIntrusiveType
    ? woData.pmIntrusiveType === 'INTRUSIVE'
    : null,
  requiredPreferences: woData.requiredPreferences,
  workOrderID: woData.workOrderID,
});
